<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <!-- <v-icon>layers</v-icon> -->
            Sales Return
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card flat>
            <v-card outlined>
              <v-card-title class="title">
                <v-flex xs4>
                  <v-text-field
                    outlined
                    dense
                    color="primary"
                    class="pa-0"
                    label="Search By Sales Bill No"
                    v-model="searchAH"
                    @input="searchItem"
                    append-icon="search"
                  />
                </v-flex>
              </v-card-title>
            </v-card>
          </v-card>
          <div v-if="Object.keys(form.items.data).length > 0">
            <div class="data-container">
              <div class="result-item-container">
                <div class="item-info">
                  <span class="head-title"
                    >Party Name:
                    {{
                      form.items.data.ledger_head
                        ? form.items.data.ledger_head.name
                        : ''
                    }}</span
                  >
                </div>
                <div class="item-info">
                  <span class="head-title"
                    >Sales Date: {{ form.items.data.entry_date }}
                  </span>
                </div>
                <div class="item-info">
                  <span class="head-title"
                    >Sale Bill Date: {{ form.items.data.sale_bill_date }}
                  </span>
                </div>
              </div>
              <div class="result-item-container">
                <div class="item-info">
                  <span class="head-title"
                    >Sale Bill No: {{ form.items.data.sale_bill_no }}</span
                  >
                </div>
                <div class="item-info">
                  <span class="head-title"
                    >Bill Type: {{ form.items.data.bill_type }}
                  </span>
                </div>
                <div class="item-info">
                  <span class="head-title"
                    >Voucher Type:
                    {{
                      form.items.data.voucher_type
                        ? form.items.data.voucher_type.name
                        : ''
                    }}
                  </span>
                </div>
              </div>
            </div>
            <table
              width="95%"
              class="in-table v-datatable v-table theme--light"
            >
              <thead>
                <tr>
                  <th class="text-xs-left"><strong>#</strong></th>
                  <th class="text-xs-left"><strong>Item</strong></th>
                  <th class="text-xs-left"><strong>WareHouse</strong></th>
                  <th class="text-xs-left"><strong>Quantity</strong></th>
                  <th class="text-xs-left"><strong>Rate </strong></th>
                  <th class="text-xs-left"><strong>Amount</strong></th>
                  <th class="text-xs-left"><strong>Return Quantity</strong></th>
                  <th class="text-xs-left"><strong>Return Amount</strong></th>
                </tr>
                <tr
                  v-for="(x, i) in form.items.data.items"
                  :key="i"
                  style="height: 0px"
                  class="footerRow"
                >
                  <td class="text-xs-left">{{ i + 1 }}</td>
                  <td class="text-xs-left">{{ x.item_name }}</td>
                  <td class="text-xs-left">{{ x.warehouse_name }}</td>
                  <td class="text-xs-left">{{ x.qty }}</td>
                  <td class="text-xs-left">{{ x.rate }}</td>
                  <td class="text-xs-left">{{ x.amount }}</td>
                  <td>
                    <input
                      type="number"
                      v-model="x.return_qty"
                      @keyup="validateQuantity(x)"
                      class="retunt-qty"
                    />
                  </td>
                  <td>{{ (x.rate * x.return_qty).numberFormat() }}</td>
                </tr>
              </thead>
              <tbody>
                <tr class="footerRow">
                  <td :colspan="5" class="text-xs-right">Sub Total:</td>
                  <td :colspan="1">
                    {{ form.items.data.sub_total.numberFormat() }}
                  </td>
                  <td class="text-xs-left">Sum Return Amount:</td>
                  <td>
                    {{ returnSumTotal(form.items.data.items).numberFormat() }}
                  </td>
                </tr>
                <tr class="footerRow">
                  <td :colspan="5" class="text-xs-right">Dis %</td>
                  <td :colspan="2">
                    {{ form.items.data.discount_in_percentage }}
                  </td>
                </tr>
                <tr class="footerRow">
                  <td :colspan="5" class="text-xs-right" width="20px">
                    Dis Amount:
                  </td>
                  <td :colspan="2">
                    {{ form.items.data.discount_in_amount.numberFormat() }}
                  </td>
                </tr>
                <tr class="footerRow">
                  <td :colspan="5" class="text-xs-right">Taxable Amount:</td>
                  <td :colspan="2">
                    {{
                      form.items.data.taxable_amount_after_discount.numberFormat()
                    }}
                  </td>
                </tr>
                <tr class="footerRow">
                  <td :colspan="5" class="text-xs-right">
                    Non Taxable Amount:
                  </td>
                  <td :colspan="2">
                    {{
                      form.items.data.non_taxable_amount_after_discount.numberFormat()
                    }}
                  </td>
                </tr>
                <tr class="footerRow">
                  <td :colspan="5" class="text-xs-right">VAT 13%:</td>
                  <td :colspan="2">
                    {{ form.items.data.vat_amount.numberFormat() }}
                  </td>
                </tr>
                <tr class="footerRow">
                  <td :colspan="5" class="text-xs-right">Total:</td>
                  <td :colspan="2">
                    {{ form.items.data.total_amount.numberFormat() }}
                  </td>
                </tr>
                <tr class="footerRow">
                  <td class="narration">Narration:</td>
                  <td class="narration" :colspan="8" width="100%">
                    {{ form.items.data.narration }}
                  </td>
                </tr>
                <tr class="footerRowNarration">
                  <td :colspan="9" class="text-xs-right">
                    <v-btn
                      color="success"
                      outlined
                      text
                      @click="save(0)"
                      :disabled="submitDisable"
                      >Return
                    </v-btn>
                    <v-btn
                      color="success"
                      outlined
                      text
                      @click="save(1)"
                      :disabled="submitDisable"
                      >Return & Print
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="data-not-found">Record not found.</div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import Form from '@/library/Form';
import { redirectDownloadUrl } from '@/library/helpers';

const NepaliDate = require('nepali-date');
const nd = new NepaliDate();

export default {
  data: () => ({
    form: new Form(
      {
        name: '',
      },
      '/api/account/inventory/sale'
    ),
    searchAH: '',

    rowsPerPageItems: [5, 10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 10,
      sortBy: 'id',
    },
    submitDisable: true,

    return_amount: 0,
    // categorySearchData: [],
    // wareHouseSearchData: [],
    categories: [],
    units: [],
    wareHouses: [],
    accountHeads: [],
    headers: [
      { text: '#', align: 'left', sortable: false, value: 'id', width: '20px' },
      { text: 'Item', value: 'name', align: 'left' },
      { text: 'Unit', value: 'bill_date', align: 'left', sortable: false },
      {
        text: 'Ware House',
        value: 'purchase_bill_date',
        align: 'left',
        sortable: false,
      },
      { text: 'Quantity', value: 'bill_no', align: 'left', sortable: false },
      { text: 'Rate', value: 'bill_type', align: 'left', sortable: false },
      { text: 'Amount', value: 'voucher_type', align: 'left', sortable: false },
      {
        text: 'Return Qauntity',
        value: 'action',
        align: 'right',
        sortable: false,
      },
    ],
  }),

  computed: {
    ...mapState(['batch']),
  },
  mounted() {
    // this.getUnits();
  },

  watch: {},

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        '?' +
        Object.keys(json)
          .map(function (key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
              );
          })
          .join('&') +
        '&saleBillNo=' +
        this.searchAH
      );
    },

    get(params) {
      if (this.searchAH) {
        let query = [null, undefined].includes(params)
          ? this.queryString()
          : params;
        this.form.get(null, query).then(({ data }) => {});
      }
    },
    searchItem: function (v) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.get();
      }, 500);
    },
    print() {
      console.log('printing....');
    },
    returnSumTotal(item) {
      let sum = 0;
      item.map((res) => {
        sum += parseFloat(res.rate * res.return_qty);
      });
      if (sum > 0) {
        this.submitDisable = false;
      } else {
        this.submitDisable = true;
      }
      return sum;
    },
    save(value) {
      let bill_no = this.searchAH;
      this.$rest
        .post('api/account/inventory/sale-return', {
          sale_bill_no: this.searchAH,
          item_data: this.form.items.data.items,
        })
        .then((res) => {
          this.$events.fire('notification', {
            message: res.data.message,
            status: 'success',
          });
          if (value === 1) {
            redirectDownloadUrl({
              uri: '/download/sales-return-item',
              queryString: `billNo=${bill_no}`,
            });
          }
        })
        .catch((e) => {
          this.$events.fire('notification', {
            message: e.response.data.message,
            status: 'error',
          });
        });
      this.searchAH = '';
      this.form.items.data = {};
    },
    validateQuantity(item) {
      if (parseFloat(item.return_qty) > parseFloat(item.qty)) {
        item.return_qty = item.qty;
      }
    },
  },
};
</script>
<style lang="scss">
tr.highlight {
  cursor: pointer;
}

.footerRow {
  height: 25px !important;
  border: none !important;
}

.in-table {
  /*width: 90%;*/

  margin: auto;
  border-collapse: collapse;
  background: rgba(184, 184, 184, 0.37);

  thead {
    /*height: 15px;*/
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
  }

  tbody td {
    /*background: red;*/
    height: 20px !important;
  }

  .narration {
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
  }

  .span-title {
    margin-left: 15px;
    margin-right: 10px;
  }
}

.result-item-container {
  display: flex !important;
  width: 100% !important;
  margin: 5px;

  .item-info {
    width: 33%;
  }

  .item-info1 {
    width: 25%;
  }
}

.data-container {
  border: 1px solid !important;
  padding: 10px !important;
}

.retunt-qty {
  border: 1px solid #ccc;
  width: 50%;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.data-not-found {
  background: #ff5252;
  color: white;
  padding: 15px;
}
</style>
